var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "guestbox " },
    _vm._l(_vm.sections, function(l, what) {
      return _c("li", { staticClass: "gb-row" }, [
        _c("div", { staticClass: "gb-td-label" }, [
          _c("label", { class: _vm.getClass(what) }, [
            _vm._v(_vm._s(_vm.getFieldLabel(what)))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "gust-btn-bl" }, [
          _c("div", { staticClass: "gb-td" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { disabled: !_vm.canDecr(what), type: "button" },
                on: {
                  click: function($event) {
                    return _vm.inc(what, -1)
                  }
                }
              },
              [_vm._v("-")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "gb-td" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default",
                attrs: { disabled: !_vm.canInc(what), type: "button" },
                on: {
                  click: function($event) {
                    return _vm.inc(what, 1)
                  }
                }
              },
              [_vm._v("+")]
            )
          ])
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }