var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "voffice-popup-box",
    {
      staticClass: "full-xxs",
      attrs: { "trigger-id": _vm.triggerId, "apply-label": _vm.applyLabel },
      on: { closed: _vm.onClosed }
    },
    [
      _c("div", { staticClass: "guestbox" }, [
        _c(
          "ul",
          _vm._l(_vm.sections, function(l, what) {
            return _c("li", { staticClass: "gb-row" }, [
              _c("td", { staticClass: "gb-td label" }, [
                _c("label", [_vm._v(_vm._s(_vm.getFieldLabel(what)))])
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "gb-td" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: !_vm.canDecr(what), type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.inc(what, -1)
                      }
                    }
                  },
                  [_vm._v("-")]
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "gb-td" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { disabled: !_vm.canInc(what), type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.inc(what, 1)
                      }
                    }
                  },
                  [_vm._v("+")]
                )
              ])
            ])
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }